import React from "react";
import { Link } from "react-router-dom";
const CtaThreeCols = () => {
  return (
    <div className="container">
      <div className="grid">
        <div className="span-md-12 span-lg-6 span-4 text-center bg-light-gray cta__1" data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="50">
          <div className="bp_cta">
            <div className="cta_content">
              <img src="/assets/images/building-process/cta-book.png" alt="" />
              <p>Free Guide to the Process of Buying and build a new home </p>
            </div>
            <div className="cta_footer">
              <Link className="button dark" href="#">
                Download The Guide
              </Link>
            </div>
          </div>
        </div>

        <div className="span-md-12 span-lg-6 span-4 text-center bg-light-gray" data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="100">
          <div className="bp_cta cta__2">
            <div className="cta_content">
              <p>
                We build on your own land. Find out how we simplify the process
                of building a dream house
              </p>
            </div>
            <div className="cta_footer">
              <Link className="button dark" href="#">
                Download The Guide
              </Link>
            </div>
          </div>
        </div>

        <div className="span-md-12 span-lg-6 span-4 bg-medium-gray text-center p-0" data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="150">
          <div className="bp_cta cta__3">
            <div className="cta_content">
              <h3>Meet With Amanda </h3>
              <p>Get one-on-one,
                undivided attention
                during your visit.</p>
            </div>
            <div className="cta_footer text-center">
              <Link className="button dark" href="#">
                BOOK AN APPOINTMENT
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaThreeCols;
