/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import CtaThreeCols from '../components/CtaThreeCols';
import { Helmet } from 'react-helmet-async';
import "../assets/styles/templates/about.css";
import { Link } from 'react-router-dom';


const About = () => {
    const [isActive, setIsActive] = useState(false);
    return (
        <main className='about'>
            <Helmet>
                <meta
                    name="description"
                    content="Artesian Homes has been recognized for their contributions and accomplishments to the home building and construction industry. Learn more."
                />
                <meta name="keywords" content="" />
                <title>
                    About | Artesian Homes is the #1 Custom Home Builder
                </title>
            </Helmet>
            <div
                className="page_hero"
                style={{ backgroundImage: `url(/assets/images/about_banner.webp` }}
            >
                <div className="container">
                    <div className={`page_hero_content center`}>
                        <h1 className="page_hero_title">ABOUT US</h1>
                    </div>
                </div>
            </div>

            <div className="page_hero_menu">
                <ul className="flex-wrap-sm">
                    <li><Link onClick={() => document.getElementById('built-on-trust')?.scrollIntoView({ behavior: 'smooth' })} to="#built-on-trust">BUILT ON TRUST</Link></li>
                    <li><Link onClick={() => document.getElementById('our-story')?.scrollIntoView({ behavior: 'smooth' })} to="#our-story"> OUR STORY</Link></li>
                    <li><Link onClick={() => document.getElementById('guiding-principles')?.scrollIntoView({ behavior: 'smooth' })} to="#guiding-principles"> GUIDING PRINCIPLES </Link></li>
                    <li><Link onClick={() => document.getElementById('our-team')?.scrollIntoView({ behavior: 'smooth' })} to="#our-team">OUR TEAM</Link></li>
                    <li><Link onClick={() => document.getElementById('foundational-pillars')?.scrollIntoView({ behavior: 'smooth' })} to="#foundational-pillars"> FOUNDATIONAL PILLARS</Link></li>
                </ul>
            </div>
            <div className="section-padding" id='built-on-trust'>
                <div className="container-1200">
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Home Builder in Central and South Florida</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <p className='text-center' data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="100">Artesian Homes does more than just build homes.  We Build dream homes</p>
                    <div className="home-builder">
                        <div className="home-builder-img" data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <img src="assets/images/mask-building.webp" alt="" />
                        </div>
                        <div className="home-builder-content" data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <p>
                                <strong>
                                    We Make Building Fun
                                </strong>{" "}
                                A house is just a place, but home is a feeling. In the greater Orlando, Naples and Palm beach area, we’ve built a reputation for constructing homes in locations that perfectly match our clients’ exact needs. Under our ‘roof’, we never underestimate our role in helping you build the home you’ve always wanted.
                            </p>
                        </div>
                    </div>
                    <div className="home-builder">
                        <div className="home-builder-content" data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <p>
                                <strong>
                                    We Make Building Fun
                                </strong>{" "}
                                A house is just a place, but home is a feeling. In the greater Orlando, Naples and Palm beach area, we’ve built a reputation for constructing homes in locations that perfectly match our clients’ exact needs. Under our ‘roof’, we never underestimate our role in helping you build the home you’ve always wanted.
                            </p>
                        </div>
                        <div className="home-builder-img" data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <img src="assets/images/home-builders.webp" alt="" />
                        </div>
                    </div>
                    <div className="home-builder">
                        <div className="home-builder-img" data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <img src="assets/images/always-wanted.webp" alt="" />
                        </div>
                        <div className="home-builder-content" data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <p>
                                <strong>
                                    We Make Building Fun
                                </strong>{" "}
                                A house is just a place, but home is a feeling. In the greater Orlando, Naples and Palm beach area, we’ve built a reputation for constructing homes in locations that perfectly match our clients’ exact needs. Under our ‘roof’, we never underestimate our role in helping you build the home you’ve always wanted.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding bg-light-gray our-story" id='our-story'>
                <div className="container-1200">
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Our Story</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="grid gap-40 mt-20">
                        <div className="span-md-12 span-6" data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <p>Meet Rick Nayar, an Orlando kid with big dreams and an even bigger heart. After graduating from college, he dove headlong into the world of homebuilding, only to discover his vision of leaving a lasting impact on his hometown wasn't quite aligning with the profit-driven ethos of mainstream builders. Frustrated by the industry's lack of human touch, he decided there had to be a better way. Enter Artesian Homes!</p>
                            <a className='button dark' href='#' onClick={(e) => {
                                e.preventDefault();
                                setIsActive(!isActive);
                            }}>Read More</a>
                        </div>
                        <div className="span-md-12 span-6" data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <div className="video_promo">
                                <div className="video_promo_item v1" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/make-group.webp)` }}>
                                    <h3 className="mb-0">The Artesian Homes Story</h3>
                                    <div className="video_arrow">
                                        <img src="/assets/images/arrow.png" alt="" />
                                    </div>
                                    <a target="_blank" rel="noreferrer" href="http://www.youtube.com"><div className="video_icon">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`read-more-content mt-30 ${isActive ? 'active' : ''}`} data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="100">
                        <p>At Artesian Homes, culture is pinnacle. Guided by the enduring principles of our founder, Rick Nayar, all decisions reflect this cornerstone.</p>
                        <p>Nayar, deeply invested in the company's reputation, is motivated to prioritize our clients and our team. The environment created by him fosters a human connection with each client. The team at Artesian is equipped to partake in a hands-on approach, fueled by the company's mission to construct superior homes. In exchange, the team is rewarded with a familial culture that promotes personal and financial growth, transparency, and the privilege to collaborate with a group of exceptional individuals committed to mutual success.</p>
                        <p>Artesian Homes has always emphasized fostering a connection with our clients since its inception. We prioritize engaging, understanding, and gaining insights about our clients. The end product is a beautiful, functional, and fitting, bespoke home. Crafted meticulously to reflect our clients, this is something large national builders cannot effectively achieve.</p>
                        <p>Nayar's deep-rooted care for the Central and South Florida communities that inspired the creation of Artesian Homes is evident. His involvement in local organizations like Habitat for Humanity and others bears testament to this. Though we've constructed hundreds of stunning luxury homes, our profound connection to the community is the most rewarding facet. We are honored that our work will continue to shape and influence Florida for years to come.</p>
                        <p>Trust, responsible administrative practices, and outstanding communication are integral to Artesian Homes. However, ultimately, it's about people.</p>
                        <p>“The connection between people and community is what sets us apart and drives us to be the best at what we do.”</p>
                        <p>We believe that where your family lives should be meaningful. The home where your family makes its memories, where your children grow up, where all the important milestones in your life happen, should be in a community that fits your family and your lifestyle. Copper thrives on building beautiful homes and communities. The Copper team will invest the time and effort to make your home a place created especially for your family.</p>
                    </div>
                </div>
            </div>
            <div className="section-padding guiding-principles" id='guiding-principles'>
                <div className="container-1200">
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Guiding Principles</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="grid mt-20">
                        <div className="span-md-12 span-6" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="50">
                            <div className="guiding-principles-item">
                                <div className="item_image">
                                    <img src="/assets/images/guiding-principles-icon-01.svg" alt="" />
                                </div>
                                <div className="item_content">
                                    <h6>Pride in Our Quality</h6>
                                    <p>We staunchly believe in our work, upholding the highest standards in all we do. Unless we see ourselves residing in a home, rest assured, we would never consider building it.</p>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-6" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <div className="guiding-principles-item">
                                <div className="item_image">
                                    <img src="/assets/images/guiding-principles-icon-02.svg" alt="" />
                                </div>
                                <div className="item_content">
                                    <h6>Client-Centric</h6>
                                    <p>At the heart of Artesian Homes lie our esteemed clients, influencing every strategic decision we make. We accord top priority to outstanding customer-service across all our platforms and touchpoints, enriching your experience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-6" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="150">
                            <div className="guiding-principles-item">
                                <div className="item_image">
                                    <img src="/assets/images/guiding-principles-icon-03.svg" alt="" />
                                </div>
                                <div className="item_content">
                                    <h6>Exceptional People</h6>
                                    <p>Comprised of industry pioneers, our team is a robust blend of intellect, vigor, passion, emotional acuity, and an unwavering dedication to excellence. We unequivocally reject mediocrity, fostering a culture of uncompromised quality that permeates our firm, enabling our personnel with the autonomy to make informed decisions that delight our clientele.</p>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-6" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="200">
                            <div className="guiding-principles-item">
                                <div className="item_image">
                                    <img src="/assets/images/guiding-principles-icon-04.svg" alt="" />
                                </div>
                                <div className="item_content">
                                    <h6>Authentic Fun</h6>
                                    <p>Hold fast to the unconventional persona of our brand. We believe in nurturing a light-hearted atmosphere and indulging in friendly banter & jests. Ours is not a rigid work environment; we infuse fun into professionalism, achieving targets with a smile. Our chief aim? Cultivating trust-centric relationships with our clientele. And how do we achieve that? By never donning a faÃ§ade and being authentic, always.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding bg-light-gray our-team" id='our-team'>
                <div className="container-1200">
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Sales and Design</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="grid my-30">
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="50">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-1.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-2.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="150">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-3.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Executive Management Team</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="grid my-30" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <div className="span-md-12 span-4">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-4.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Tim Pratt</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Tim</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-5.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Tim Pratt</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Tim</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="150">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-6.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Tim Pratt</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Tim</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Construction Management</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="grid my-30" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <div className="span-md-12 span-4">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-7.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-8.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="150">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-9.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Marketing</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="grid my-30">
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="50">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-10.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-11.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="150">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-12.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Accounting, Finance & Purchasing</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="grid my-30">
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="50">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-13.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-14.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Administration</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="grid my-30">
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="50">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-15.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="span-md-12 span-4" data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100">
                            <div className="member_wrapper">
                                <div className="member_image">
                                    <img src="/assets/images/team/team-16.webp" alt="" />
                                </div>
                                <div className="member_content">
                                    <div className="member_content_area">
                                        <h3>Amanda Mendez-Williams</h3>
                                        <span>Sales Counselor</span>
                                        <a className='button' href="#">Meet Amanda</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding" id='foundational-pillars'>
                <div className="container-1200">
                    <div className="section_title text-center" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Foundational Pillars​</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="foundational-pillars-content" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="100">
                        <h5>Build Better</h5>
                        <p>Defined: Certainly, our brand promise refers to the homes we build–they will be better quality, better value, better for the environment and better for modern living and technology. But it’s about more than that. It’s about building a better company and team of employees.  It’s about building a better sales process that is collaborative with our customers. It’s about building better communities and neighborhoods. In the end, it’s about building better lives.</p>
                        <h5>Thoughtful</h5>
                        <p>Thinking ahead. Considering not what just will sell homes, but also what will make our customers happy with their home.</p>
                        <h5>Progressive</h5>
                        <p>Employing best practices, constantly pursing “better”, challenging the status quo</p>
                        <h5>Responsible</h5>
                        <p>Looking for ways to do more than just what’s good for business, but what may be better for our customers, the planet, etc.</p>
                        <h5>Open and Honest Communication</h5>
                        <p>We are invested in nurturing authentic, long-term relationships with our valued clients. That’s why we encourage open dialogue wherever possible, allowing for honest feedback and collaborative experiences.</p>
                    </div>
                </div>
            </div>
            <div className="section-padding bg-light-gray" id='core-values'>
                <div className="container-1200">
                    <div className="section_title text-center mb-30" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <h2>Core Values</h2>
                        <img
                            src="/assets/images/seperator.png"
                            alt=""
                        />
                    </div>
                    <div className="core-values-content" data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="100">
                        <div className="core-values-item">
                            <img src="/assets/images/core-value-01.svg" alt="" />
                            <h5>Genuine</h5>
                        </div>
                        <div className="core-values-item">
                            <img src="/assets/images/core-value-02.svg" alt="" />
                            <h5>Fun</h5>
                        </div>
                        <div className="core-values-item">
                            <img src="/assets/images/core-value-03.svg" alt="" />
                            <h5>Genuine</h5>
                        </div>
                        <div className="core-values-item">
                            <img src="/assets/images/core-value-04.svg" alt="" />
                            <h5>Genuine</h5>
                        </div>
                        <div className="core-values-item">
                            <img src="/assets/images/core-value-05.svg" alt="" />
                            <h5>Genuine</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding-t">
                <CtaThreeCols />
            </div>
        </main>
    );
};

export default About;