import React from 'react';
import CtaThreeCols from '../components/CtaThreeCols';
import { Helmet } from 'react-helmet-async';
import "../assets/styles/templates/building-process.css";
import Accordion from '../components/accordion';
const contactFrom = process.env.REACT_APP_CONTACTFROM;

const about = [
    {
        title: "How long have you been in Business?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "Who are our clients?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "Is Artesian Homes Licensed and Insured?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
];
const financing = [
    {
        title: "What are your financing options?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What are my loan options for a custom home?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "Explain a Construction-to-Permanent Loan more please?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What is the difference between a construction-to-permanent loan and a construction loan?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "How does the disbursement of funds work during the construction phase of a construction-to-permanent loan?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What happens if the construction phase exceeds the estimated timeline outlined in the loan agreement?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What are the advantages of a Construction to Permanent loan?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What are FHA and VA Construction loans?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },

];
const construction = [
    {
        title: "Do you work with homebuyers with or without lots?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "Should I buy land before or after I speak with my home builder?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What are the responsibilities of the owners during the construction of a new custom home?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What does Design build mean?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What features are included in your standard home list?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What is a pre-construction services agreement?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What is your price per sq ft?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What happens in the case of Storm damage or vandalism during construction?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What is value engineering?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What is the last opportunity you will have to make any changes?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "How custom is an Artesian Custom Home?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "Do we have to use your Architect and engineering teams?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What about permitting?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "How do I know if I qualify for an Artesian Custom Home?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "What Type of Warranty do you offer?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "Can you explain the custom home building process?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "Why choose design/build?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
    {
        title: "Do you need to hire your own architect ?",
        content:
            "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore rem libero, asperiores perferendis voluptas tenetur earum, odit nemo, nisi iusto ducimus magnam reprehenderit iure consequatur commodi aperiam necessitatibus laudantium aut quis. Cum quidem cumque aut maxime, eius mollitia amet vel tempore, porro ad nobis fuga, facilis soluta magnam dolorem ex.",
        image: "",
    },
];

const Faq = () => {

    return (
        <main className='faq'>
            <Helmet>
                <meta
                    name="description"
                    content="Artesian Homes has been recognized for their contributions and accomplishments to the home building and construction industry. Learn more."
                />
                <meta name="keywords" content="" />
                <title>
                    Faq | Artesian Homes is the #1 Custom Home Builder
                </title>
            </Helmet>
            <div className="block block-type-padding">
                <div
                    className="the-pad"
                    style={{
                        backgroundColor: "var(--color-)",
                        "--padding-desktop": "90px",
                        "--padding-mobile": "30px",
                    }}
                ></div>
            </div>
            <div className="container">
                <div className="grid gap-40">
                    <div className="span-1"></div>
                    <div className="span-lg-12 span-6" data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <div className="faq_title">
                            <h1>Questions? We’ve got answers!</h1>
                            <p>There can be a lot of questions that come up when building a custom Florida home. We took a crack at answering a few of the most common ones for you:</p>
                        </div>
                        <div className="faq_one">
                            <h4>First the basics about us:</h4>
                            <Accordion items={about} />
                        </div>
                        <div className="faq_two">
                            <h4>Financing Questions</h4>
                            <Accordion items={financing} />
                        </div>
                        <div className="faq_three">
                            <h4>Questions about Construction of a Custom Home</h4>
                            <Accordion items={construction} />
                        </div>
                    </div>
                    <div className="span-lg-12 span-4" data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-delay="50">
                        <div className="faq_contact">
                            <div className="faq_contact_title">
                                <h1>Request a closing cost quote today.</h1>
                                <p className='faq_contact_subtitle'>See if you qualify for the <span>REAL REBATE</span></p>
                            </div>

                            <form
                                action={contactFrom}
                                method="post"
                                className="wpcf7-form init"
                            >
                                <div className="pre-submit">
                                    <div className="contact-form">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            required=""
                                            defaultValue=""
                                            placeholder="First Name*"
                                        />
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            required=""
                                            defaultValue=""
                                            placeholder="Last Name*"
                                        />
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            required=""
                                            defaultValue=""
                                            placeholder="Email*"
                                        />
                                        <select name="transaction" id="transaction">
                                            <option value="option-01">Type of transaction</option>
                                            <option value="option-02">Option 02</option>
                                            <option value="option-03">Option 03</option>
                                            <option value="option-04">Option 04</option>
                                        </select>
                                        <select name="transaction" id="transaction">
                                            <option value="option-01">What's your role?</option>
                                            <option value="option-02">Option 02</option>
                                            <option value="option-03">Option 03</option>
                                            <option value="option-04">Option 04</option>
                                        </select>
                                        <input
                                            type="text"
                                            name="city_or_country"
                                            id="city_or_country"
                                            required=""
                                            defaultValue=""
                                            placeholder="Property City or County*"
                                        />
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            required=""
                                            defaultValue=""
                                            placeholder="Phone"
                                        />
                                        <select name="transaction" id="transaction">
                                            <option value="option-01">Is this property under contract?</option>
                                            <option value="option-02">Option 02</option>
                                            <option value="option-03">Option 03</option>
                                            <option value="option-04">Option 04</option>
                                        </select>
                                        <input
                                            type="text"
                                            name="price"
                                            id="price"
                                            required=""
                                            defaultValue=""
                                            placeholder="Purchase/Sales Price (if applicable)*"
                                        />
                                        <input
                                            type="text"
                                            name="loan_abount"
                                            id="loan_abount"
                                            required=""
                                            defaultValue=""
                                            placeholder="Loan Amount (if applicable)"
                                        />
                                        <label className="communications" htmlFor="communications">
                                            <input
                                                id="communications"
                                                type="checkbox"
                                                name="communications"
                                                required=""
                                                defaultValue=""
                                            />
                                            I agree to receive communications from Artesian Homes
                                        </label>
                                        <button
                                            className="formSubmitButton"
                                            name="submit"
                                            defaultValue="Submit"
                                            type="submit"
                                        >
                                            Get Your Quote
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="span-1"></div>
                </div>
            </div>
            <div className="section-padding-t">
                <CtaThreeCols />
            </div>
        </main>
    );
};

export default Faq;