import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";

function Accordion({ items, type }) {
  const [isActive, setIsActive] = useState(0);

  const handleClick = (index) => {
    setIsActive(index === isActive ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="single_accordion">
          <div
            className={`accordion_header ${index === isActive ? "active" : ""}`}
            onClick={() => handleClick(index)}
          >
            <div className="accordion_title">
              {type !== 'coverage' ? `${index + 1}.` : ''} {item.title}
            </div>

            <div className="accordion_icon">
              <div className="accordion_icon">
                {index === isActive ? <FaMinus /> : <FaPlus />}
              </div>
            </div>
          </div>

          {index === isActive && (
            <div className="accordion_content grid align-items-center">
              {item.image ? (
                <>
                  <div className="span-4">
                    <img src={item.image} alt={item.title} />
                  </div>
                  <div className="span-8">
                    <p>{item.content}</p>
                  </div>
                </>
              ) : (
                <div className="span-12">
                  {type === 'coverage' ? (
                    <div className="grid" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                  ) : <p>{item.content}</p>}
                </div>
              )
              }
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Accordion;
